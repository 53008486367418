import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import {LanguageService} from "src/app/services/language/language.service"
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'niceville-portfolio';
  
  constructor(
    private titleService: Title,
    private metaService: Meta,
    private translateService: TranslateService,
    private location: Location,
    private languageService: LanguageService
    ){
    }
  ngOnInit(): void{
    
    this.languageService.initLanguage()
    this.languageService.changeLanguage('it');


    this.titleService.setTitle( "Roberto Trombetta | Niceville" );

    this.metaService.addTags([
      {name: 'keywords', content: 'virtual reality, app, web app, web sites, videogames, Unreal Engine, software, develop, Italy, Friuli, Pordenone'},
      {name: 'description', content: 'I design customized solutions for web applications and virtual reality, guiding my clients through every stage: from the initial idea to implementation. I work closely with companies from various industries to develop innovative projects that combine creativity and technology.'},
      {name: 'keywords', content: 'realtà virtuale, app, web app, siti web, videogiochi, Unreal Engine, software, sviluppo, Italia, Friuli, Pordenone'},
      {name: 'description', content: 'Progetto soluzioni personalizzate per applicazioni web e realtà virtuale, guidando i miei clienti in ogni fase: dall’idea iniziale alla realizzazione. Lavoro a stretto contatto con aziende di diversi settori per sviluppare progetti innovativi che combinano creatività e tecnologia.'}
    ]);
    
    
    AOS.init(); 

  }
}
